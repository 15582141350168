<template>
  <div class="app-settings">
    <v-layout>
      <v-flex xs11>
        <div class="mb-4 sw-h3">{{ $t("group_page.images") }}</div>
        <v-layout row class="mb-5 mx-n5 pb-5">
          <v-flex xs4 class="px-5">
            <v-card flat class="fill-height sw-primary-bg">
              <v-card-text class="py-5 text-center">
                <TranslatableTooltip
                  :content="$t('group_page.group_image_label_tooltip')"
                  class="center"
                >
                  <div class="mb-2 sw-h5">
                    {{ $t("group_page.group_image_label") }}
                  </div>
                </TranslatableTooltip>
                <MediaManager
                  v-model="groupAttributes.group_image"
                  :media-type="'images'"
                  label=""
                />
              </v-card-text>
              <div class="px-4 pb-3 sw-caption text-center">
                {{ $t("group_page.group_image_hint") }}
              </div>
            </v-card>
          </v-flex>
          <v-flex xs4 class="px-5">
            <v-card flat class="fill-height sw-primary-bg">
              <v-card-text class="py-5 text-center">
                <TranslatableTooltip
                  :content="$t('group_page.main_background_label_tooltip')"
                  class="center"
                >
                  <div class="mb-2 sw-h5">
                    {{ $t("group_page.main_background_label") }}
                  </div>
                </TranslatableTooltip>
                <MediaManager
                  v-model="groupAttributes.main_background"
                  :media-type="'images'"
                  :image-preview="true"
                  :image-preview-background-size="'cover'"
                  label=""
                />
              </v-card-text>
              <div class="px-4 pb-3 sw-caption text-center">
                {{ $t("group_page.main_background_hint") }}
              </div>
            </v-card>
          </v-flex>
          <v-flex xs4 class="px-5">
            <v-card flat class="fill-height sw-primary-bg">
              <v-card-text class="py-5 text-center">
                <TranslatableTooltip
                  :content="$t('group_page.app_header_image_label_tooltip')"
                  class="center"
                >
                  <div class="mb-2 sw-h5">
                    {{ $t("group_page.app_header_image_label") }}
                  </div>
                </TranslatableTooltip>
                <MediaManager
                  v-model="groupAttributes.app_header_image"
                  :media-type="'images'"
                  label=""
                />
              </v-card-text>
              <div class="px-4 pb-3 sw-caption text-center">
                {{ $t("group_page.app_header_image_hint") }}
              </div>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <TranslatableTooltip
              :content="$t('group_page.opacity_label_tooltip')"
            >
              <div class="mb-2 sw-h5">
                {{ $t("group_page.opacity_label") }}
              </div>
            </TranslatableTooltip>
          </v-flex>
          <v-flex xs12>
            <v-layout>
              <v-flex xs3
                ><v-slider
                  v-model="opacity"
                  :max="max"
                  :min="min"
                  step="0.01"
                  class="ma-0"
                  :color="themeStyleVariables.accent"
                ></v-slider
              ></v-flex>
              <v-flex xs2 class="px-4 pt-1">
                {{ opacityPercentages }} %
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-layout>
              <v-flex xs3>
                <v-switch
                  v-model="mobileShowTitleBar"
                  class="ma-0 mb-5"
                  hide-details
                >
                  <template slot="label">
                    <div class="black--text">
                      {{ $t("group_page.mobile_show_group_name_in_title_bar_label") }}
                    </div>
                  </template>
                </v-switch>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <div class="mb-2 sw-h5">{{ $t("group_page.colors") }}</div>
        <v-layout>
          <v-flex xs7>
            <v-layout row wrap>
              <v-flex xs12 class="mb-4">
                <ColorPicker
                  v-model="groupAttributes.primary_color"
                  :label="$t('group_page.primary_color_label')"
                />
              </v-flex>
              <v-flex xs12 class="mb-4">
                <v-divider class="sw-secondary-bg"></v-divider>
              </v-flex>
              <v-flex xs12 class="mb-4">
                <ColorPicker
                  v-model="groupAttributes.secondary_color"
                  :label="$t('group_page.secondary_color_label')"
                />
              </v-flex>
              <v-flex xs12>
                <v-divider class="sw-secondary-bg"></v-divider>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker.vue";
import variables from "@/assets/styles/variables.scss";

export default {
  data: () => ({
    min: 0,
    max: 1,
  }),
  computed: {
    themeStyleVariables() {
      return variables || {};
    },
    groupAttributes: {
      get() {
        return this.$store.getters.groupPageGroupAttributes;
      },
      set(val) {
        this.$store.dispatch("setGroupPageGroupAttributes", val);
      },
    },
    opacity: {
      get() {
        return this.groupAttributes.app_promoted_item_opacity === null
          ? 1
          : this.groupAttributes.app_promoted_item_opacity;
      },
      set(val) {
        this.groupAttributes.app_promoted_item_opacity = val;
      },
    },
    opacityPercentages() {
      return Math.floor(this.opacity * 100);
    },
    mobileShowTitleBar: {
      get() {
        return this.groupAttributes.mobile_show_group_name_in_title_bar === null
          ? false
          : this.groupAttributes.mobile_show_group_name_in_title_bar;
      },
      set(val) {
        this.groupAttributes.mobile_show_group_name_in_title_bar = val;
      },
    },
  },
  components: {
    ColorPicker,
  },
};
</script>

<style lang="scss">
.v-input--slider {
  .v-slider {
    .v-slider__track,
    .v-slider__track-fill {
      background: #707070;
    }

    .v-slider__track__container {
      transform: none !important;
      height: 1px;
    }
  }
}
</style>
