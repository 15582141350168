<template>
  <div class="colorpicker">
    <div class="d-flex align-center">
      <div :style="{ backgroundColor: value }" class="color">
        <input type="color" ref="colorPicker" />
      </div>
      <div class="pl-3 full-width caption">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '#ffffff',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    colorPicker: null,
    isEventInProgress: false,
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    value(val) {
      this.colorPicker.value = val;
    },
  },
  methods: {
    setModel(val) {
      this.isEventInProgress = false;
      this.model = val;
    },
  },
  mounted() {
    this.colorPicker = this.$refs.colorPicker;

    this.colorPicker.value = this.value;

    this.colorPicker.addEventListener(
      'input',
      (event) => {
        if (!this.isEventInProgress) {
          this.isEventInProgress = true;
          setTimeout(() => this.setModel(event.target.value), 100);
        }
      },
      false,
    );
    this.colorPicker.addEventListener(
      'change',
      (event) => (this.model = event.target.value),
      false,
    );
    this.colorPicker.select();
  },
};
</script>

<style lang="scss" scoped>
.colorpicker {
  .color {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border: 1px solid #dadada;
    border-radius: 30px;
    position: relative;

    input {
      position: absolute;
      width: 50px;
      height: 50px;
      top: 0;
      left: 0;
      opacity: 0;
      border-radius: 30px;
      overflow: hidden;
      cursor: pointer;
    }
  }
}
</style>
