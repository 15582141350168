<template>
  <div class="landing-page-settings">
    <v-form data-vv-scope="landing-page">
      <v-layout>
        <v-flex xs11>
          <div class="mb-4 sw-h3">{{ $t("group_page.general_settings") }}</div>
          <v-layout row wrap class="pb-5">
            <v-flex xs12>
              <v-layout row wrap class="mb-5">
                <v-flex xs12 class="mb-2 sw-h5">{{
                  $t("group_page.domain_name")
                }}</v-flex>
                <v-flex xs12 class="sw-caption">
                  {{ $t("group_page.domain_name_hint") }}
                </v-flex>
                <v-flex xs6>
                  <v-layout row class="align-center">
                    <v-flex xs12>
                      <v-text-field
                        v-model="group.subdomain"
                        data-vv-name="subdomain"
                        :data-vv-as="$t('group_page.domain_name_data_as')"
                        :placeholder="$t('group_page.domain_name_placeholder')"
                        hide-details
                        @input="group.subdomain = group.subdomain.toLowerCase()"
                      ></v-text-field>
                      <ErrorMessages
                        :error-messages="
                          errors.collect('landing-page.subdomain')
                        "
                      />
                    </v-flex>
                    <v-flex v-if="defaultDomain && defaultDomain.length > 0">
                      <span class="pr-2">.{{ defaultDomain }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 v-if="group.subdomain">
                  <span class="mr-2">{{ $t("group_page.domain_hint") }}</span>
                  <a :href="landingPageLink" target="_blank" class="sw-accent">
                    <font-awesome-icon icon="external-link-alt" />
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mb-5">
              <v-switch
                v-model="groupAttributes.lp_event_timer"
                class="ma-0"
                hide-details
              >
                <template slot="label">
                  <div class="black--text">
                    {{ $t("group_page.show_countdown") }}
                  </div>
                </template>
              </v-switch>
            </v-flex>
            <v-flex v-if="groupAttributes.lp_event_timer" xs4>
              <v-layout row wrap class="mb-5">
                <v-flex xs12 class="mb-4 sw-h5">{{
                  $t("group_page.date_of_event")
                }}</v-flex>
                <v-flex xs12 class="mb-3">
                  <date-picker
                    v-model="groupAttributes.lp_event_start_date"
                    type="datetime"
                    :first-day-of-week="1"
                    :minute-step="5"
                    format="MMMM DD, YYYY H:mm"
                    :lang="datePickerLang"
                    :editable="false"
                    width="100%"
                  ></date-picker>
                </v-flex>
                <v-flex xs12 class="px-2 grey--text sw-caption"
                  >{{ $t("current-timezone") }}: {{ userTimezone }}</v-flex
                >
              </v-layout>
              <v-layout row wrap class="mb-5">
                <v-flex xs12 class="sw-h5">
                  {{ $t("group_page.event_start_text") }}
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="groupAttributes.lp_event_start_text"
                    :placeholder="$t('group_page.event_start_text_placeholder')"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <div class="mb-4 sw-h3">{{ $t("group_page.header") }}</div>
          <v-layout row class="mb-5 mx-n5">
            <v-flex xs4 class="px-5">
              <v-card flat class="fill-height sw-primary-bg">
                <v-card-text class="py-5 text-center">
                  <MediaManager
                    v-model="groupAttributes.lp_header_background_image"
                    :media-type="'images'"
                    :label="$t('group_page.header_label')"
                  />
                </v-card-text>
                <div class="px-4 pb-3 sw-caption text-center">
                  {{ $t("group_page.header_hint") }}
                </div>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mb-5">
            <v-flex xs12>
              <v-switch
                v-model="groupAttributes.lp_transparent_toolbar"
                class="ma-0"
                :true-value="false"
                :false-value="true"
                hide-details
              >
                <template slot="label">
                  <div class="black--text">
                    {{ $t("group_page.transparent_toolbar") }}
                  </div>
                </template>
              </v-switch>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mb-5">
            <v-flex xs12>
              <v-switch
                v-model="groupAttributes.lp_show_group_name_in_header"
                class="ma-0"
                hide-details
              >
                <template slot="label">
                  <div class="black--text">
                    {{ $t("group_page.lp_show_group_name_in_header_label") }}
                  </div>
                </template>
              </v-switch>
            </v-flex>
          </v-layout>
          <v-layout class="mb-5">
            <v-flex xs4>
              <v-layout row wrap class="mb-5">
                <v-flex xs12>
                  <TranslatableTooltip
                    :content="$t('group_page.banner_text_tooltip')"
                  >
                    <div class="mb-2 sw-h5">
                      {{ $t("group_page.banner_text") }}
                    </div>
                  </TranslatableTooltip>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="groupAttributes.lp_header_headline"
                    :placeholder="$t('group_page.banner_text_placeholder')"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <div class="mb-4 sw-h3">{{ $t("group_page.text") }}</div>
          <v-layout row wrap class="mb-5 pb-5">
            <v-flex xs12 class="mb-4 sw-h5">{{
              $t("group_page.intro_text")
            }}</v-flex>
            <v-flex xs12>
              <QuillEditor
                v-model="groupAttributes.lp_intro_text"
                :config="introTextEditorConfig"
              />
            </v-flex>
          </v-layout>
          <div class="mb-4 sw-h3">{{ $t("group_page.full_screen_image") }}</div>
          <v-layout row class="mb-5 mx-n5 pb-5">
            <v-flex xs4 class="px-5">
              <v-card flat class="fill-height sw-primary-bg">
                <v-card-text class="py-5 text-center">
                  <TranslatableTooltip
                    :content="$t('group_page.full_screen_image_label_tooltip')"
                    class="center"
                  >
                    <div class="mb-2 sw-h5">
                      {{ $t("group_page.full_screen_image_label") }}
                    </div>
                  </TranslatableTooltip>
                  <MediaManager
                    v-model="groupAttributes.lp_full_screen_image"
                    :media-type="'images'"
                    label=""
                  />
                </v-card-text>
                <div class="px-4 pb-3 sw-caption text-center">
                  {{ $t("group_page.full_screen_image_hint") }}
                </div>
              </v-card>
            </v-flex>
          </v-layout>
          <div class="mb-4 sw-h3">{{ $t("group_page.contact_form") }}</div>
          <v-layout row wrap class="mb-5 pb-5">
            <v-flex xs12>
              <v-switch
                v-model="groupAttributes.lp_contact_form"
                class="ma-0"
                hide-details
              >
                <template slot="label">
                  <div class="black--text">
                    {{ $t("group_page.contact_form_hint") }}
                  </div>
                </template>
              </v-switch>
            </v-flex>
            <v-flex v-if="groupAttributes.lp_contact_form" xs12>
              <v-layout>
                <v-flex xs4>
                  <v-layout row wrap class="my-5">
                    <v-flex xs12 class="sw-h5">{{
                      $t("group_page.contact_form_recipient")
                    }}</v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="group.contact_form_recipient_email"
                        :error-messages="errors.collect('landing-page.email')"
                        v-validate.disable="'email'"
                        data-vv-name="email"
                        :data-vv-as="$tc('email', 2)"
                        :placeholder="
                          $t('group_page.contact_form_recipient_placeholder')
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 class="mb-4 sw-h5">{{
                  $t("group_page.contact_form_thank_you_text")
                }}</v-flex>
                <v-flex xs12>
                  <QuillEditor
                    v-model="groupAttributes.lp_contact_form_thank_you_text"
                    :config="thankYouTextEditorConfig"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <div class="mb-4 sw-h3">{{ $t("group_page.footer") }}</div>
          <v-layout row wrap class="mb-5">
            <v-flex xs12>
              <TranslatableTooltip
                :content="$t('group_page.footer_text_tooltip')"
              >
                <div class="mb-2 sw-h5">
                  {{ $t("group_page.footer_text") }}
                </div>
              </TranslatableTooltip>
            </v-flex>
            <v-flex xs12>
              <QuillEditor
                v-model="groupAttributes.lp_footer_text"
                :config="footerTextEditorConfig"
                class="mb-3"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mx-n5 mb-5 pb-5">
            <v-flex xs4 class="px-5">
              <v-card flat class="fill-height sw-primary-bg">
                <v-card-text class="py-5 text-center">
                  <TranslatableTooltip
                    :content="$t('group_page.footer_logo_label_tooltip')"
                    center
                  >
                    <div class="mb-2 sw-h5">
                      {{ $t("group_page.footer_logo_label") }}
                    </div>
                  </TranslatableTooltip>
                  <MediaManager
                    v-model="groupAttributes.lp_footer_logo"
                    :media-type="'images'"
                    label=""
                  />
                </v-card-text>
                <div class="px-4 pb-3 sw-caption text-center">
                  {{ $t("group_page.footer_logo_hint") }}
                </div>
              </v-card>
            </v-flex>
          </v-layout>
          <div class="mb-4 sw-h3">{{ $t("group_page.colors") }}</div>
          <v-layout>
            <v-flex xs7>
              <v-layout row wrap>
                <v-flex xs12 class="mb-4">
                  <ColorPicker
                    v-model="groupAttributes.lp_primary_color"
                    :label="$t('group_page.primary_color_label')"
                  />
                </v-flex>
                <v-flex xs12 class="mb-4">
                  <v-divider class="sw-secondary-bg"></v-divider>
                </v-flex>
                <v-flex xs12 class="mb-4">
                  <ColorPicker
                    v-model="groupAttributes.lp_secondary_color"
                    :label="$t('group_page.secondary_color_label')"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-divider class="sw-secondary-bg"></v-divider>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import ColorPicker from "@/components/ColorPicker.vue";

export default {
  inject: ["parentValidator"],
  computed: {
    defaultDomain() {
      return process.env.VUE_APP_DEFAULT_DOMAIN;
    },
    group: {
      get() {
        return this.$store.getters.groupPageGroup;
      },
      set(val) {
        this.$store.dispatch("setGroupPageGroup", val);
      },
    },
    groupAttributes: {
      get() {
        return this.$store.getters.groupPageGroupAttributes;
      },
      set(val) {
        this.$store.dispatch("setGroupPageGroupAttributes", val);
      },
    },
    landingPageLink() {
      return `https://${this.group.subdomain}.${this.defaultDomain}`;
    },
    introTextEditorConfig() {
      return {
        label: "",
        placeholder: this.$t("group_page.intro_text_placeholder"),
        iframes: true,
        style: {
          height: "320px",
        },
      };
    },
    thankYouTextEditorConfig() {
      return {
        label: "",
        placeholder: this.$t(
          "group_page.contact_form_thank_you_text_placeholder",
        ),
        style: {
          height: "320px",
        },
      };
    },
    footerTextEditorConfig() {
      return {
        label: "",
        placeholder: this.$t("group_page.footer_text_placeholder"),
        iframes: true,
        style: {
          height: "320px",
        },
      };
    },
  },
  components: {
    "date-picker": DatePicker,
    ColorPicker,
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>

<style lang="scss" scoped></style>
